

























































































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import FloodFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/filters/FloodFilterHandlerModule/FloodFilterHandlerModuleBuilder";

import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../components/filters/layout/FilterSettingLayout.vue";
import { FloodOptionsEnum } from '@/includes/logic/Modules/models/modules/filters/FloodFilterHandlerModule/FloodFilterHandlerModule';

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue';
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue';
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types';
import { SelectOption } from "piramis-base-components/src/logic/types";
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SwitchInput,
    NumberInput,
    TimeUnitInput,
    SelectInput,
    NestedContent
  },
})
export default class FloodFilterHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: FloodFilterHandlerModuleBuilder

  get floodOptions(): Array<SelectOption> {
    return Object.values(FloodOptionsEnum).map(o => ({
      label: this.$t(`flood_option_${ o.toLowerCase() }`).toString(),
      value: o
    }))
  }

  get wordsMultiplyOptions(): Array<SelectOption> {
    return [
      { label: this.$t('flood_duplicate_words_multiply_0').toString(), value: 0 },
      { label: this.$t('flood_duplicate_words_multiply_1').toString(), value: 1 },
      { label: this.$t('flood_duplicate_words_multiply_2').toString(), value: 2 },
      { label: this.$t('flood_duplicate_words_multiply_3').toString(), value: 3 },
      { label: this.$t('flood_duplicate_words_multiply_4').toString(), value: 4 },
    ]
  }
}
